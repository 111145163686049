@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Outfit:wght@100..900&display=swap');

::selection {
  background: #FF0069; /* Change the background color of the selection */
  color: #ffffff; /* Change the color of the selected text */
}

body {
  width: 100vw;
  margin: 0; /* Optional: Remove default margin */
  padding: 0; /* Optional: Remove default padding */
}

h1, h2, h3 {
  font-family: "Outfit", sans-serif;
}

p {
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  line-height: 1.5;
}


html, body {
  overflow-x: hidden;
}
